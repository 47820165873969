import React from "react"
import { Text, Button } from "deskie-ui"
import { navigate } from "gatsby"
import Container from "../components/Container"
import PageWrapper from "../components/PageWrapper"
import styled from "styled-components"

const Header = styled(Container)`
  margin-top: -6rem;
  padding-top: 8rem;
  padding-bottom: 6rem;

  h1 {
    color: white;
    font-weight: 600;
    font-size: min(
      max(1.75rem, calc(1.75rem + ((1vw - 0.4rem) * 1.875))),
      2.5rem
    );
    line-height: 1.3;
    margin: 0 0 0.5rem;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }

  background-image: url("/paths.svg");
  background-size: auto 160%;
  background-position: 100% 0%;
  background-repeat: no-repeat;
`

const Content = styled(Container)`
  h2 {
    color: ${props => props.theme.colors.primary};
  }

  & > * + * {
    margin-top: 1em;
  }

  ul {
    padding-left: 1.5rem;
  }

  a {
    color: ${props => props.theme.colors.primary};
  }

  .button {
    margin-top: 2rem;
  }
`

function Privacy() {
  return (
    <>
      <Header>
        <Text h1>Privacy Statement</Text>
      </Header>
      <PageWrapper>
        <Content>
          <Text p>
            Deskie, gevestigd aan Burgemeester Roelenweg 13K, is verantwoordelijk voor de
            verwerking van persoonsgegevens zoals weergegeven in deze
            privacyverklaring.
          </Text>
          <Text p>
            Contactgegevens:
            <br />
            https://www.deskie.nl/
            <br />
            Burgemeester Roelenweg 13K
            <br />
            038 760 1060
          </Text>
          <Text h2>Persoonsgegevens die wij verwerken</Text>
          <Text p>
            Deskie verwerkt je persoonsgegevens doordat je gebruik maakt van
            onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.
            Hieronder vind je een overzicht van de persoonsgegevens die wij
            verwerken:
          </Text>
          <Text p>
            <ul>
              <li>Voor- en achternaam</li>
              <li>Adresgegevens</li>
              <li>Telefoonnummer</li>
              <li>E-mailadres</li>
              <li>IP-adres</li>
              <li>
                Overige persoonsgegevens die je actief verstrekt bijvoorbeeld
                door een profiel op deze website aan te maken, in
                correspondentie en telefonisch
              </li>
              <li>Locatiegegevens</li>
              <li>Gegevens over jouw activiteiten op onze website</li>
              <li>
                Gegevens over jouw surfgedrag over verschillende websites heen
                (bijvoorbeeld omdat dit bedrijf onderdeel is van een
                advertentienetwerk)
              </li>
              <li>Lijst met contactgegevens van de klant via een app</li>
              <li>Internetbrowser en apparaat type</li>
              <li>Bankrekeningnummer</li>
            </ul>
          </Text>
          <Text h2>
            Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
          </Text>
          <Text p>
            Onze website en/of dienst heeft niet de intentie gegevens te
            verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij
            ze toestemming hebben van ouders of voogd. We kunnen echter niet
            controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan
            ook aan betrokken te zijn bij de online activiteiten van hun
            kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld
            worden zonder ouderlijke toestemming. Als je er van overtuigd bent
            dat wij zonder die toestemming persoonlijke gegevens hebben
            verzameld over een minderjarige, neem dan contact met ons op via
            info@deskie.nl, dan verwijderen wij deze informatie.
          </Text>
          <Text h2>
            Deskie verwerkt jouw persoonsgegevens voor de volgende doelen:
          </Text>
          <Text p>
            <ul>
              <li>Het afhandelen van jouw betaling</li>
              <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>
              <li>
                Je te kunnen bellen of e-mailen indien dit nodig is om onze
                dienstverlening uit te kunnen voeren
              </li>
              <li>
                Je te informeren over wijzigingen van onze diensten en producten
              </li>
              <li>Je de mogelijkheid te bieden een account aan te maken</li>
              <li>
                Deskie analyseert jouw gedrag op de website om daarmee de
                website te verbeteren en het aanbod van producten en diensten af
                te stemmen op jouw voorkeuren.
              </li>
              <li>
                Deskie verwerkt ook persoonsgegevens als wij hier wettelijk toe
                verplicht zijn, zoals gegevens die wij nodig hebben voor onze
                belastingaangifte.
              </li>
            </ul>
          </Text>
          <Text h2>Geautomatiseerde besluitvorming</Text>
          <Text p>
            Deskie neemt #responsibility op basis van geautomatiseerde
            verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen
            hebben voor personen. Het gaat hier om besluiten die worden genomen
            door computerprogramma's of -systemen, zonder dat daar een mens
            (bijvoorbeeld een medewerker van Deskie) tussen zit. Deskie gebruikt
            de volgende computerprogramma's of -systemen: #use_explanation
          </Text>
          <Text h2>Hoe lang we persoonsgegevens bewaren</Text>
          <Text p>
            Deskie bewaart je persoonsgegevens niet langer dan strikt nodig is
            om de doelen te realiseren waarvoor je gegevens worden verzameld.
            Wij hanteren de volgende bewaartermijnen voor de volgende
            (categorieën) van persoonsgegevens: #retention_period
          </Text>
          <Text h2>Delen van persoonsgegevens met derden</Text>
          <Text p>
            Deskie verstrekt uitsluitend aan derden en alleen als dit nodig is
            voor de uitvoering van onze overeenkomst met jou of om te voldoen
            aan een wettelijke verplichting.
          </Text>
          <Text h2>
            Cookies, of vergelijkbare technieken, die wij gebruiken
          </Text>
          <Text p>
            Deskie gebruikt functionele, analytische en tracking cookies. Een
            cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
            website wordt opgeslagen in de browser van je computer, tablet of
            smartphone. Deskie gebruikt cookies met een puur technische
            functionaliteit. Deze zorgen ervoor dat de website naar behoren
            werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden
            worden. Deze cookies worden ook gebruikt om de website goed te laten
            werken en deze te kunnen optimaliseren. Daarnaast plaatsen we
            cookies die jouw surfgedrag bijhouden zodat we op maat gemaakte
            content en advertenties kunnen aanbieden. Bij jouw eerste bezoek aan
            onze website hebben wij je al geïnformeerd over deze cookies en
            hebben we je toestemming gevraagd voor het plaatsen ervan.
          </Text>
          <Text p>
            Je kunt je afmelden voor cookies door je internetbrowser zo in te
            stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook
            alle informatie die eerder is opgeslagen via de instellingen van je
            browser verwijderen. Zie voor een toelichting:{" "}
            <a
              href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/
            </a>
          </Text>
          <Text h2>Gegevens inzien, aanpassen of verwijderen</Text>
          <Text p>
            Je hebt het recht om je persoonsgegevens in te zien, te corrigeren
            of te verwijderen. Daarnaast heb je het recht om je eventuele
            toestemming voor de gegevensverwerking in te trekken of bezwaar te
            maken tegen de verwerking van jouw persoonsgegevens door Deskie en
            heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je
            bij ons een verzoek kan indienen om de persoonsgegevens die wij van
            jou beschikken in een computerbestand naar jou of een ander, door
            jou genoemde organisatie, te sturen.
          </Text>
          <Text p>
            Je kunt een verzoek tot inzage, correctie, verwijdering,
            gegevensoverdraging van je persoonsgegevens of verzoek tot
            intrekking van je toestemming of bezwaar op de verwerking van jouw
            persoonsgegevens sturen naar{" "}
            <a
              href="mailto:info@deskie.nl"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@deskie.nl
            </a>
            . Om er zeker van te zijn dat het verzoek tot inzage door jou is
            gedaan, vragen wij jou een kopie van je identiteitsbewijs met het
            verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine
            readable zone, de strook met nummers onderaan het paspoort),
            paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter
            bescherming van je privacy. We reageren zo snel mogelijk, maar
            binnen vier weken, op jouw verzoek.
          </Text>
          <Text p>
            Deskie wil je er tevens op wijzen dat je de mogelijkheid hebt om een
            klacht in te dienen bij de nationale toezichthouder, de Autoriteit
            Persoonsgegevens. Dat kan via de volgende link:{" "}
            <a
              href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
            </a>
          </Text>
          <Text h2>Hoe wij persoonsgegevens beveiligen</Text>
          <Text p>
            Deskie neemt de bescherming van jouw gegevens serieus en neemt
            passende maatregelen om misbruik, verlies, onbevoegde toegang,
            ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
            Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd
            zijn of er aanwijzingen zijn van misbruik, neem dan contact op met
            onze klantenservice of via{" "}
            <a
              href="mailto:info@deskie.nl"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@deskie.nl
            </a>
          </Text>
        </Content>
      </PageWrapper>
    </>
  )
}

export default Privacy
